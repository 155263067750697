.apartment{
    margin-top: 50px;
    @media (max-width: 768px){
        //margin-top: 100px;
    }
    .container-header1{
        width: 100%;
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        color: #9a7438;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 600px;
          margin-top: -50px;
        }
    
    }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        font-size: 40px;
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        text-align: center;
        color: #334442;
        @media (max-width: 768px) {
            font-size: 50px;
        }
    }
}