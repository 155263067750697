.background-container {
    background-image: url("../../../src//Media/Desktop_Podomorobdg.webp"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 0px 0px 25px 25px;
    @media (max-width: 768px) {
      background-image: url("../../../src//Media/Mobile_Podomorobdg.webp");
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center; 
    
    }
  }