
.about {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .container-about{
        width: 100%;
        display: flex;
        flex-direction: column;

        h1 {
            width: 90%;
            font-size: 35px;
            margin-left: 75px;
            font-weight: 400;
            color: #9a7438;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
                font-size: 20px;
                margin-left: -5px;
            }

        }
        .container-desk {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        .desk {
            width: 80%;
            font-size: 16px;
            color: rgb(84, 84, 84);
            @media (max-width: 768px) {
                width: 90%;
                text-align: center;
            }
        }
    }
    .container-benefit {
        width: 100%;
        margin-top: 15px;margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .benefit-list{
            width: 80%;

            .penawaranpoin {
                color: rgb(84, 84, 84);
                span {
                    font-size: 15px;
                    color: rgb(84, 84, 84);
                }
            }
        }
    }
}
    .gambarabout {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items:center ;
        @media (max-width: 768px) {
            margin-top: -90px;
        }
        img {
            width: 75%;
            border-radius: 10px;
            margin-top: 50px;
            @media (max-width: 768px) {
                width: 90%;
            }
        }
    }
    .container-wa{
        width: 100%;
        margin-left: 75px;
        //margin-top: -10px;
        @media (max-width: 768px) {
          width: 0%;
          visibility: hidden;
        }
      .buttonpenawaran {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        //margin-top: 30px;
        //margin-left: 15%;
        background-color: #03351a;
        width: 25%;
        
        padding: 12px 30px;
        border: 1px solid white; /* Add this line for black border */
        border-color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: light;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease, color 0.3s ease;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          //margin-left: 15%;
          //margin-top: -30px;
          margin-bottom: -20px;
          width: 0%;
          visibility: hidden;
  
        }
        &:hover {
          background-color: transparent;
          color: #03351a;
          border-color: #03351a;
        }
        
      }
    }
}