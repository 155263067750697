.produk {
  width: 100%;
  margin-top: 100px;
  @media (max-width: 768px) {
    width: 100%;
  }
  h1 {
    width: 100%;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    color: #9a7438;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 30px;
      margin-top: -50px;
    }
  }


.cardhome-containerf {
    margin-top: 25px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto  ;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .judul-fresco {
    margin-top: 20px;
    text-align: center;
    color: #14345c;
    font-size: 52px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
  .devider {
    background-color: #14345c;
    height: 200px;
    width: 100%;
    transform: scale(1, 1);
    fill: #ffffff;
    @media (max-width: 768px) {
      height: 40px;
    }
  }
}