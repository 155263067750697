.apartment{
    margin-top: 50px;
    width: 100%;
    @media (max-width: 768px){
        margin-top: 100px;
    }
    .container-header1{
        width: 100%;
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        color: #9a7438;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 30px;
          margin-top: -50px;
        }
    
    }
    .heroregia {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
        @media (max-width: 768px){
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
      }
        .slider-regia {
            width: 65%;
            margin-top: 20px;
            //margin-left: 5%;
            border-radius: 20px;
            @media (max-width: 768px){
              width: 90%;
              margin-right: 20px;
          }
          .imagecard {
            border-radius: 30px;
          }
  
            .slick-next::before,
            .slick-prev::before {
              font-family: "slick";
              font-size: 30px;
            }
            .slick-list {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
            }
      
            .slick-dots {
              position: absolute; /* Make dots absolute for positioning within slides */
              bottom: 10px; /* Adjust vertical positioning */
              left: 50%; /* Center dots horizontally */
              transform: translateX(-50%); /* Center dots precisely */
              z-index: 1; /* Ensure dots are above image content */
            }
            .slick-next {
              right: 25px;
            }
            .slick-prev {
              z-index: 1;
              left: 25px;
            }
            .slick-dots {
              li {
                margin: 0;
              }
            }
        }
        .desk-regia {
            width: 50%;
            text-align: center;
            padding-left: 20px;
            color: white;
            font-size: 20px;
            font-weight: 200;
            font-family: "poppins" sans-serif;
            @media (max-width: 768px){
              width: 100%;
              margin-top: 10px;
              font-size: 16px;
              display: flex;
              justify-content: center;
              text-align: center;
              margin-bottom: -20px;
              padding-right: 20px;
          }
        }
        .cardstudent {
            width: 25%;
            margin-left: 15px;
            margin-top: 10px;
            background-color: #fff;
      
            padding-bottom: 10px;
            hr {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 15px;
              width: 90%;
            }
            @media (max-width: 768px) {
              width: 90%;
              margin-bottom: 20px;
              margin-left: 0px;
              height: auto;
            }
            .imagecard {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            }
            .imagecard1 {
              width: 100%;
            }
            .slick-next::before,
            .slick-prev::before {
              font-family: "slick";
              font-size: 30px;
            }
            .slick-list {
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            }
      
            .slick-dots {
              position: absolute; /* Make dots absolute for positioning within slides */
              bottom: 10px; /* Adjust vertical positioning */
              left: 50%; /* Center dots horizontally */
              transform: translateX(-50%); /* Center dots precisely */
              z-index: 1; /* Ensure dots are above image content */
            }
            .slick-next {
              right: 25px;
            }
            .slick-prev {
              z-index: 1;
              left: 25px;
            }
            .slick-dots {
              li {
                margin: 0;
              }
            }
      
            .container-rumah {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-content: center;
              padding: 10px;
              .nama-rumah {
                width: 100%;
                font-family: "poppins" sans-serif;
                font-size: 30px;
                font-weight: 500;
                color: #063f20;
              }
            }
            .gridmap {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
              align-items: center;
              .icon {
                margin-top: 5px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
              }
              .namalokasi{
              width: 100%;
              font-size: 18px;
              color: #4b4b4b;
              }
            }
      
            .containercicilan {
              width: 100%;
              margin-top: -5px;
              display: flex;
              background-color: white;
      
              .judulkartu {
                width: 50%;
                padding: 10px;
                margin: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .startfrom {
                  color: #063f20;
                  font-family: "Poppins" sans-serif;
                  font-size: 20px;
                  font-weight: 400;
                }
              }
              .cicilansumban {
                width: 50%;
                padding: 10px;
                border-radius: 10px 0px 0 10px;
                color: #063f20;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: white;
                .angka {
                  font-family: "Poppins";
                  font-size: 20px;
                  font-weight: 500;
                }
                .ket {
                  font-family: "Poppins";
                  font-size: 10px;
                  padding-top: 10px;
                  font-weight: 500;
                }
              }
            }
            hr {
              width: 95%;
            }
            .gridspek {
              padding-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              //background: rgb(90,131,103);
              //padding-top: 15px;
              //padding-bottom: 15px;
              border: 1px #c6995e;
              border-radius: 0px 0px 10px 10px;
              gap: 10px;
              span {
                color: #091c34;
                  display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5px;
                padding-right: 10px;
                @media (max-width: 768px) {
                  padding-right: 20px;
                  font-size: 12px;
                }
              }
            }
            .cluster-button {
              display: flex;
              justify-content: center;
              //padding-top: 25px;
              
              .cluster-whatsapp {
                display: inline-block;
                padding: 10px 100px;
                cursor: pointer;
                border: solid 1px white;
                border-radius: 20px;
                background-color: #063f20; 
                color: #ffffff;
                font-size: 16px;
                &:hover {
                  background-color: transparent;
                  color: #063f20;
                  border-color: #063f20;
                  
              }
              }
            }
          }
        }
    
}