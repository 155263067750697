@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-footer {
  width: 100%;
  display: flex;
  margin-bottom: -160px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  box-shadow: 0 0 5px 0;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .container-atas {
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    //margin: 15px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin: 0px;
      margin-bottom: -40px;
    }

    .container-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 75%;
      //padding: 20px;

      @media (max-width: 768px) {
        width: 80%;
        //margin-bottom: -20px;
      }

      .gambar-logo {
        width: 85%;

        @media (max-width: 768px) {
          width: 75%;
          padding-bottom: 20px;
        }
      }
    }

    .container-deskripsi {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      @media (max-width: 768px) {
        width: 100%;
        justify-content: left;
        align-items: left;
        margin-top: -30px;
        margin-bottom: 30px;
      }

      .alamat {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 20%;
        font-weight: 400;
        margin-bottom: 5%;
        font-family: "Poppins";
        font-size: 14px;

        @media (max-width: 768px) {
          justify-content: left;
          align-items: left;
          width: 100%;
          align-content: left;
        }

        h1 {
          font-size: 40px;
          align-items: center;
          justify-content: center;
          font-family: "Poppins", serif;
          line-height: 1.2em;
          font-weight: 500;
          color: #9a7438;;
          margin-bottom: -10px;

          @media (max-width: 768px) {
            font-size: 30px;
            margin-left: -35%;
          }
        }

        h2 {
          color: #036e44;
          font-weight: 400;
          font-size: 25px;
          margin-bottom: -10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            width: 100%;
            font-size: 24px;
            margin-left: -20%;
          }
        }

        h3 {
          color: #939292;
          font-weight: 400;
          font-size: 15px;
          margin-bottom: -10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            width: 100%;
            font-size: 15px;
            margin-left: -15%;
          }
        }

        h4 {
          color: #939292;
          font-weight: 400;
          font-size: 15px;
          margin-bottom: -10px;
          margin-bottom: 10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            font-size: 15px;
            margin-left: -30%;
          }
          
        }
        .footer-contact {
          width: 100%;
          @media (max-width: 768px) {
            //font-size: 15px;
            margin-left: -15%;
          }
        }

      }

      @media (max-width: 768px) {
        width: 80%;
      }

      .contact {
        font-weight: 400;
        font-family: "Poppins", serif;
        font-size: 14px;
        color: #1c244b;

        @media (max-width: 768px) {
          margin-top: 5px;
        }
      }

      //    .privasi{

      //  }
    }
  }
hr {
  width: 75%;
}
  .container-bawah {
    width: 100%;
    background: #063f20;
    padding: 20px 0;
    text-align: center;
    color: white;
    font-weight: lighter;
    font-family: "Roboto" sans-serifs;
    font-size: 18px;
    font-weight: 800px;
  }
  
}